import React, { useState } from 'react';
import './ApplicationForm.css';
import { db, storage } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function ApplicationForm({ onBack }) {
  const [formData, setFormData] = useState({
    companyName: '',
    companyLocation: '',
    introduction: '',
    storageCapacity: '',
    operatingHours: '',
    phoneNumber: '',
    businessCertificate: null, // 선택 (옵션)
    spacePhotos: [], // 다중 파일 업로드를 위해 배열로 저장
    facilityInfo: '',
    settlementAccount: '',
  });

  // 파일 입력이 multiple일 경우 모두 배열에 저장
  const handleChange = (e) => {
    const { name, value, files, multiple } = e.target;
    if (files) {
      if (multiple) {
        setFormData((prev) => ({
          ...prev,
          [name]: Array.from(files),
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: files[0],
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 사업자등록증 업로드 (옵션)
      let businessCertificateURL = "";
      if (formData.businessCertificate) {
        const certificateRef = ref(
          storage,
          `businessCertificates/${Date.now()}_${formData.businessCertificate.name}`
        );
        await uploadBytes(certificateRef, formData.businessCertificate);
        businessCertificateURL = await getDownloadURL(certificateRef);
      }

      // 공간 사진 업로드 (여러 파일)
      let spacePhotosURLs = [];
      if (formData.spacePhotos && formData.spacePhotos.length > 0) {
        for (let file of formData.spacePhotos) {
          const photoRef = ref(
            storage,
            `spacePhotos/${Date.now()}_${file.name}`
          );
          await uploadBytes(photoRef, file);
          const url = await getDownloadURL(photoRef);
          spacePhotosURLs.push(url);
        }
      }

      // Firestore에 저장할 데이터 구성
      const dataToSave = {
        companyName: formData.companyName,
        companyLocation: formData.companyLocation,
        introduction: formData.introduction,
        storageCapacity: formData.storageCapacity,
        operatingHours: formData.operatingHours,
        phoneNumber: formData.phoneNumber,
        businessCertificateURL,
        spacePhotosURLs,
        facilityInfo: formData.facilityInfo,
        settlementAccount: formData.settlementAccount,
        createdAt: new Date(),
      };

      // Firestore 컬렉션 "applications"에 데이터 추가
      await addDoc(collection(db, "applications"), dataToSave);
      alert("신청이 완료되었습니다!");
      onBack(); // 제출 후 메인 화면으로 돌아가기
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("신청 도중 에러가 발생했습니다.");
    }
  };

  return (
    <div className="application-form-container">
      <div className="form-wrapper">
        <h1>신청서 작성</h1>
        <form onSubmit={handleSubmit} className="application-form">
          <div className="form-group">
            <label>업체명:</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>업체 위치:</label>
            <input
              type="text"
              name="companyLocation"
              value={formData.companyLocation}
              onChange={handleChange}
              required
            />
          </div>
          <fieldset className="form-group fieldset-group">
            <legend>업체 정보</legend>
            <div className="form-group">
              <label>소개글:</label>
              <textarea
                name="introduction"
                value={formData.introduction}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>짐 보관 개수:</label>
              <input
                type="number"
                name="storageCapacity"
                value={formData.storageCapacity}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>운영 시간:</label>
              <input
                type="text"
                name="operatingHours"
                value={formData.operatingHours}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>전화번호:</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
          </fieldset>
          <div className="form-group">
            <label>사업자등록증 (선택):</label>
            <input
              type="file"
              name="businessCertificate"
              onChange={handleChange}
              accept="image/*,application/pdf"
            />
          </div>
          <div className="form-group">
            <label>공간 사진:</label>
            <input
              type="file"
              name="spacePhotos"
              onChange={handleChange}
              accept="image/*"
              multiple
            />
          </div>
          <div className="form-group">
            <label>편의 시설 정보:</label>
            <input
              type="text"
              name="facilityInfo"
              value={formData.facilityInfo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>정산 계좌 정보:</label>
            <input
              type="text"
              name="settlementAccount"
              value={formData.settlementAccount}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn-submit">
            신청 완료
          </button>
        </form>
        <button onClick={onBack} className="btn-back">
          뒤로가기
        </button>
      </div>
    </div>
  );
}
