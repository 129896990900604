import React from "react";
import "./SplitImage.css";
import imageright from "./assets/store_money2.jpg";
import imageleft from "./assets/image2.jpg";

function SplitImage() {
  return (
    <div className="split-container">
      <div className="split left">
        <img
          src={imageleft}
          alt="Left"
          className="split-image"
        /> 
        <div className="overlay-text">여행객은 짐을 맡기고</div>
      </div>
      <div className="split right">
        <img
          src={imageright}
          alt="Right"
          className="split-image"
        />
        <div className="overlay-text">매장은 돈을 버는</div>
      </div>
    </div>
  );
}

export default SplitImage;
