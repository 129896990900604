import React, { useState } from 'react';
import './App.css';
import defaultBg from './assets/bg.jpg';
import LegalInfo from './LegalInfo';
import Page3Section from './Page3';
import StatsSection from './StatsSection';
import SplitImage from './SplitImage';
import ApplicationForm from './ApplicationForm';



export default function App() {
  const [bgImage, setBgImage] = useState(defaultBg);
  const [showForm, setShowForm] = useState(false);

  const page2 = [
    { badge: '01', title: '매장 내 공간만 있다면 \n 누구나 신청 가능' },
    { badge: '02', title: '간편한 보관, 회수 시스템' },
    { badge: '03', title: '저렴한 수수료 & 매달 정산', subtitle: '(업계 평균 대비 20% 저렴)' }
  ];

  if (showForm) {
    return <ApplicationForm onBack={() => setShowForm(false)} />;
  }

  return (
    <div className="container">

      <header className="hero" style={{ backgroundImage: `url(${bgImage})` }}>
        
        <h1>BAGSPACE</h1>
        <p>당신의 짐이 불편하다고 느껴질 때</p>
      </header>

      <section className="work-section">
        <h2 className="work-title">
          매장 내 <span className="highlight">남는 공간</span>이 <br/>
          새로운 수익원이 됩니다
        </h2>
        <div className="work-cards">
            {page2.map(({ badge, title, subtitle }) => (
        <div className="work-card" key={badge}>
            <span className="work-badge">{badge}</span>
            <h3>{title}</h3>
                {subtitle && <p className="work-subtext">{subtitle}</p>}
        </div>
            ))}
        </div>
      </section>
      
      <Page3Section/>
      
      <StatsSection/>
       
      <SplitImage/>
     


      <section className="info">
        
        <h2>수화물 보관 서비스의 새로운 패러다임</h2>
        <p>BAGSPACE</p>
      </section>

      <footer className="footer">
        <button className="btn-contact" onClick={() => setShowForm(true)}>
          지금 신청하기
        </button>
      </footer>
      

      

      <LegalInfo />
    </div>
  );
}