// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDwHf2Qb7ny3LibaBmv-Avn_9Jkh-laEek",
    authDomain: "bagspace-d4315.firebaseapp.com",
    projectId: "bagspace-d4315",
    storageBucket: "bagspace-d4315.firebasestorage.app",
    messagingSenderId: "192343577222",
    appId: "1:192343577222:web:b2ea7dc285b75a2ac8b707",
    measurementId: "G-DBG5R1WXM5"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
