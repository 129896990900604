// LegalInfo.jsx
import React from 'react';

const LegalInfo = () => {
  return (
    <section className="legal">
      <p>
        SC<br />
        대구광역시 북구 대학로 80 경북대학교 글로벌플라자 
      </p>
      <p>
        전자금융분쟁처리 TEL: 010-2896-7579&nbsp;&nbsp;FAX: 053-950-2569<br />
        E-MAIL: <a>semin2071@gmail.com</a>
      </p>
    </section>
  );
};

export default LegalInfo;
