// StatsSection.js
import React from 'react';
import './StatsSection.css';
import AppICON from './assets/BAGSPACE_LOGO.png';
import MAPICON from './assets/map.png';

function StatsSection() {
  return (
    <section className="stats-section">
      <h2 className="stats-title">
        <span className="highlight">별도의 비용</span> 없이 <span className="highlight">앱 </span>하나로
      </h2>

      <div className="stats-container">
        {/* 왼쪽 통계 박스 */}
        <div className="stat-box">
          <p className="stat-label">BAGSPACE 앱</p>
          <img 
            src={AppICON}
            alt="앱 다운로드 아이콘" 
            className="stat-icon" 
          />
          <div className="stat-info">
            <p className="stat-value">2025년 6월 출시</p>
            <p className="stat-note">사전 예약 中</p>
          </div>
        </div>

        {/* 오른쪽 통계 박스 */}
        <div className="stat-box">
          <p className="stat-label">BAGSPACE ZONE</p>
          <img 
            src={MAPICON}
            alt="BAGSPACE 아이콘" 
            className="stat-icon" 
          />
          <div className="stat-info">
            <p className="stat-value">전국 모든 도시</p>
            <p className="stat-note">2025년 3월 기준</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsSection;
