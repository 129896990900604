import React from 'react';
import defaultBg from './assets/image4.jpg';

export default function Page3Section() {
  return (
    <section className="hero-section" style={{ backgroundImage: `url(${defaultBg})` }}>
      <div className="hero-content">
        <h1 className="hero-logo">BAGSPACE</h1>
        <h2 className="hero-title">
          매장의 남는 공간을 활용해<br/>
          수익을 내고 싶은 누구나
        </h2>
        <button className="hero-btn">
          사전 신청하기</button>
      </div>
      
    </section>
  );
}
